const initState = {
  productInfo: null,
  customerInfoObject: null,
  sellOrderObject: null,
  selectedDate: null,
  selectedAddress: null,
  isPreOrderValidated: false,
  sellOrderPostLoading: false,
  sellOrderPostData: null,
  orderDispatchPostLoading: false,
  orderDispatchPostData: null,
  sellOrderFileAttached: null,
  isDirectDeliverySelected: false,
  isCommercialCreditSelected: false,
  isRedirect: false,
  updateQuantityOrderedData: null,
  loadingUpdateQuantityOrdered: false,
}

export default function sellOrderReducer(state = initState, action) {
  switch (action.type) {
    case 'CLEAN_SELL_ORDER':
      return {
        ...state,
        sellOrderObject: null,
        selectedDate: null,
        selectedAddress: null,
        isPreOrderValidated: false,
        sellOrderPostLoading: false,
        sellOrderPostData: null,
        orderDispatchPostLoading: false,
        orderDispatchPostData: null,
        isCommercialCreditSelected: null,
        sellOrderFileAttached: null,
        isRedirect: false,
      }
    case 'SET_SELL_ORDER_OBJECT':
      return {
        ...state,
        sellOrderObject: action.payload,
      }
    case 'SET_SELECTED_DATE':
      return {
        ...state,
        selectedDate: action.payload,
      }
    case 'SET_PREORDER_VALIDATION':
      return {
        ...state,
        isPreOrderValidated: action.payload,
      }
    case 'SET_SELECTED_ADDRESS':
      return {
        ...state,
        selectedAddress: action.payload,
      }
    case 'SET_CUSTOMER_INFO':
      return {
        ...state,
        customerInfoObject: action.payload,
      }
    case 'SET_DELIVERY_TYPE':
      return {
        ...state,
        deliveryType: action.payload,
      }
    case 'SELL_ORDER_POST_LOADING':
      return {
        ...state,
        sellOrderPostLoading: action.payload,
      }
    case 'SELL_ORDER_POST_RESULT':
      return {
        ...state,
        sellOrderPostData: action.payload,
      }
    case 'SELL_ORDER_STATUS':
      return {
        ...state,
        sellOrderPostData: {
          ...state.sellOrderPostData,
          Data: {
            ...state.sellOrderPostData.Data,
            SalesDocumentInfo: {
              ...state.sellOrderPostData.Data.SalesDocumentInfo,
              DescriptionStatus: action.payload.value,
              DocHeaderStatusId: action.payload.key,
            },
          },
        },
      }
    case 'DISPATCH_ORDER_POST_LOADING':
      return {
        ...state,
        orderDispatchPostLoading: action.payload,
      }
    case 'DISPATCH_ORDER_POST_RESULT':
      return {
        ...state,
        orderDispatchPostData: action.payload,
      }
    case 'UPDATE_QUANTITY_ORDERED_RESULT':
      return {
        ...state,
        updateQuantityOrderedData: action.payload,
      }
    case 'LOADING_UPDATE_QUANTITY_ORDERED':
      return {
        ...state,
        loadingUpdateQuantityOrdered: action.payload,
      }
    case 'SELL_ORDER_FILE_ATTACHED':
      return {
        ...state,
        sellOrderFileAttached: action.payload,
      }
    case 'IS_COMMERCIAL_CREDIT_SELECTED':
      return {
        ...state,
        isCommercialCreditSelected: action.payload,
      }
    case 'CLEAN_COMMERCIAL_CREDIT_SELECTED':
      return {
        ...state,
        isCommercialCreditSelected: null,
      }
    case 'SET_IS_DIRECT_DELIVERY':
      return {
        ...state,
        isDirectDeliverySelected: action.payload,
      }
    case 'SET_IS_REDIRECT':
      return {
        ...state,
        isRedirect: action.payload,
      }
    default:
      return state
  }
}
