import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import {
  getCustomerById,
  getCustomersAction,
  getPriceListByCustomerId,
  updateCustomerAction,
} from 'redux/actions/clientsActions'
import { PersonPlusFill } from 'react-bootstrap-icons'
import { faSpinner, faSquarePen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { agregarCliente } from 'redux/actions/cotizacionActions'
import MetodoEntrega from 'Components/MetodoEntrega'
import { CircularProgress } from '@mui/material'
import { allCreditAccountsService } from 'redux/services/clientsServices'
import { getFormatedClient } from './utils/getFormatedClient'
import { getUpdateClientPayload } from './utils/getUpdateClientPayload'
import search from '../../../../Assets/Images/BuscarCotizacion/search.svg'
import styles from './styles.module.css'
import { AlertModal } from 'Views/Clientes/CrearCliente/components/AlertModal/AlertModal'
import {
  VAPermissions,
  regexCellPhone,
  regexEmail,
  regexPhone,
  telephoneTypeIds,
} from 'Utils/constants'
import useValidatePermission from 'Hooks/useValidatePermission'

const INITIAL_VALUE = {
  email: '',
  phone: '',
}

export const CustomerSection = ({
  client,
  hasDefaultWarehouse,
  selectedAddress,
  setSelectedAddress,
  setDireccion,
  direccion,
  setCustomerCounted,
  setClient,
  customerCounted,
  setShowModalCustomerCounted,
  setDefaultWarehouseToQuotation,
}) => {
  const dispatch = useDispatch()
  const searchTextRef = useRef(null)
  const companyId = useSelector(state => state.company.CompanyId)
  const isCustomerUser = useSelector(state => state.login.isCustomer)
  const salesAgentId = useSelector(
    state => state.login.user?.AuthenticationInfo?.UserId
  )
  const [seeMore, setSeeMore] = useState(false)
  const [userNotfound, setUserNotfound] = useState(false)
  const [clientsOptions, setClientOptions] = useState([])
  const [edit, setEdit] = useState(false)
  const [updateClientValues, setUpdateClientValues] = useState(INITIAL_VALUE)
  const [validateOnSuccessCallAddress, setValidateOnSuccessCallAddress] =
    useState(false)
  const [priceList, setPriceList] = useState({})
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false)
  const [clientDetails, setClientDetails] = useState(null)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState({
    phone: '',
    email: '',
  })

  const [creditAccounts, setCreditAccounts] = useState([])
  const canCreateEditCustomer = useValidatePermission(
    VAPermissions.createNaturalClient
  )

  useEffect(() => {
    const allCreditAccounts = async () => {
      try {
        const data = await allCreditAccountsService({
          CustomerId: client.CustomerId,
          SearchText: null,
          PageSize: 20,
          PageNumber: 1,
        })
        setCreditAccounts(data.data.Data)
      } catch (error) {
        console.error('allCreditAccounts ->', error)
      }
    }
    if (client?.CustomerId) {
      allCreditAccounts()
    }
  }, [client?.CustomerId])

  const telfType = clientDetails?.generalStore
    ? clientDetails.generalStore[0]?.generalTelephone[0]?.telephoneTypeId?.toLowerCase()
    : clientDetails?.generalTelephone
    ? clientDetails.generalTelephone[0]?.telephoneTypeId?.toLowerCase()
    : null
  const isPhone = telfType === telephoneTypeIds.cellPhone

  const fetchCustomers = async searchString => {
    try {
      setClientOptions([])
      setUserNotfound(false)
      setIsLoadingCustomer(true)
      setErrors({ ...errors, phone: '', email: '' })
      setEdit(false)
      const payload = {
        companyId,
        GlobalExecution: true,
        SearchText: searchString,
        RoleName: 'Customer',
        ...(isCustomerUser && { SalesAgentId: salesAgentId }),
        PageSize: 10,
        pageNumber: 1,
      }
      const Data = await dispatch(getCustomersAction(payload))
      if (Data && Data?.length > 0) {
        setClientOptions(Data)
      } else {
        setUserNotfound(true)
        throw new Error(Data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoadingCustomer(false)
    }
  }

  const handleClienteSearch = e => {
    if (e.keyCode === 13) {
      fetchCustomers(e.target.value)
    }
  }

  const handleClient = async client => {
    setCustomerCounted(null)
    setClient(client)
    setClientOptions([])
    const id = client.CustomerId
    const priceListId =
      client?.CustomerGroup.length > 0
        ? client.CustomerGroup[0].CustomerGroupId
        : client?.PriceListId
        ? client.PriceListId
        : null
    dispatch(
      agregarCliente({
        id: client.CustomerId,
        customerType: client.CustomerTypeId,
        cliente: client.FullName,
        correo: client.Email,
        externalCode: client.SystemCode,
        phoneNumber: client?.PhoneNumber,
        priceListId,
      })
    )
    setUpdateClientValues({
      email: client?.Email || '',
      phone: client?.PhoneNumber || '',
    })
    const PriceListInfo = await dispatch(getPriceListByCustomerId(id))
    setPriceList(PriceListInfo[0])
    setDefaultWarehouseToQuotation()
    const userData = await dispatch(getCustomerById(id))
    const formatedClient = getFormatedClient(userData)
    setClientDetails(formatedClient)
    const customerEmail =
      client?.Email ||
      formatedClient?.generalStore?.[0]?.generalEmail?.[0]?.address ||
      formatedClient?.email ||
      ''
    const customerPhone =
      client?.PhoneNumber ||
      formatedClient?.generalStore?.[0]?.generalTelephone?.[0]?.number ||
      ''
    setUpdateClientValues({
      email: customerEmail,
      phone: customerPhone,
    })
    dispatch(
      agregarCliente({
        id: client.CustomerId,
        customerType: client.CustomerTypeId,
        cliente: client.FullName,
        correo: customerEmail,
        externalCode: client.SystemCode,
        phoneNumber: customerPhone,
        priceListId,
      })
    )
  }

  const handleUpdateClient = async () => {
    try {
      setUpdateLoading(true)
      const payload = getUpdateClientPayload(clientDetails, updateClientValues)
      const response = await dispatch(updateCustomerAction(payload))
      if (response?.data?.Status?.Code === 200) {
        setClient({
          ...client,
          PhoneNumber: updateClientValues.phone,
          Email: updateClientValues.email,
        })
        const priceListId =
          client?.CustomerGroup.length > 0
            ? client.CustomerGroup?.[0]?.CustomerGroupId
            : client?.PriceListId
            ? client.PriceListId
            : null
        dispatch(
          agregarCliente({
            id: client.CustomerId,
            customerType: client.CustomerTypeId,
            cliente: client.FullName,
            correo: updateClientValues.email,
            externalCode: client.SystemCode,
            phoneNumber: updateClientValues?.phone,
            priceListId,
          })
        )
        setSuccess(true)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setEdit(false)
      setUpdateLoading(false)
    }
  }

  const handleChangePhone = text => {
    if (isNaN(text)) return
    const validatePhoneNumber = (data, phoneNumber) => {
      let error = ''
      const telephoneType = data.generalStore
        ? data.generalStore[0]?.generalTelephone[0]?.telephoneTypeId?.toLowerCase()
        : data.generalTelephone[0]?.telephoneTypeId?.toLowerCase()
      const phoneErrorMessage =
        telephoneType === telephoneTypeIds.cellPhone
          ? t('CREATE_CLIENT_T.INTRODUCE_VALID_CELLPHONE')
          : t('CREATE_CLIENT_T.INTRODUCE_VALID_PHONE_FORMAT')

      if (!phoneNumber) {
        error = phoneErrorMessage
      } else {
        const phoneNumberValidation = {
          [telephoneTypeIds.house]: regexPhone,
          [telephoneTypeIds.office]: regexPhone,
          [telephoneTypeIds.cellPhone]: regexCellPhone,
        }[telephoneType]

        if (phoneNumberValidation && !phoneNumberValidation.test(phoneNumber)) {
          error = phoneErrorMessage
        } else error = ''
      }
      return error
    }
    setUpdateClientValues({ ...updateClientValues, phone: text })
    const error = validatePhoneNumber(clientDetails, text)
    if (error) {
      setErrors({ ...errors, phone: error })
    } else setErrors({ ...errors, phone: '' })
  }

  const handleChangeEmail = text => {
    const validateEmail = (data, email) => {
      let error = ''
      if (!email) {
        error = t('CREATE_CLIENT_T.INTRODUCE_VALID_EMAIL')
      } else if (!regexEmail.test(email)) {
        error = t('CREATE_CLIENT_T.INTRODUCE_VALID_EMAIL')
      }
      return error
    }
    setUpdateClientValues({ ...updateClientValues, email: text })
    const error = validateEmail(clientDetails, text)
    if (error) {
      setErrors({ ...errors, email: error })
    } else setErrors({ ...errors, email: '' })
  }

  return (
    <>
      <div className="d-flex container-search-counted">
        <div className="product-sale-search-bar">
          <input
            ref={searchTextRef}
            type="text"
            placeholder={t('SELL_BY_PROD_T.SEARCHBAR_PLACEHOLDER')}
            onKeyDown={handleClienteSearch}
            disabled={isLoadingCustomer}
          />
          {isLoadingCustomer ? (
            <FontAwesomeIcon
              icon={faSpinner}
              className="spinner seach-spinner"
              style={{ fontSize: 25 }}
            />
          ) : (
            <img
              src={search}
              className="seach-icon"
              alt="search-icon"
              onClick={() => fetchCustomers(searchTextRef.current.value)}
            />
          )}
          {clientsOptions.length > 0 ? (
            <div className="product-search-options">
              {clientsOptions.map(client => {
                return (
                  <h2
                    key={client.CustomerId}
                    onClick={() => handleClient(client)}
                    className="client-option-sp">
                    {client?.FullName}
                  </h2>
                )
              })}
            </div>
          ) : null}
        </div>
        <button
          disabled={(client && !customerCounted) || isLoadingCustomer}
          onClick={() => {
            setShowModalCustomerCounted(true)
            setCustomerCounted(null)
          }}
          className="btn-customer-counted">
          <PersonPlusFill />
        </button>
      </div>
      {userNotfound && (
        <p className={styles.userNotfound}>
          {t('PRODUCTS_SEARCH_T.RESULTS_NOT_FOUND')}
        </p>
      )}
      <div className="product-sale-client">
        <div className={styles.editClienteButton}>
          <h2 className="product-sale-client-header">
            {t('SELL_BY_PROD_T.CLIENT_LABEL')}
          </h2>
          {!edit && client?.CustomerId && canCreateEditCustomer && (
            <FontAwesomeIcon
              icon={faSquarePen}
              onClick={() => {
                setEdit(true)
                setSeeMore(true)
                const customerEmail =
                  client?.Email ||
                  clientDetails?.generalStore?.[0]?.generalEmail?.[0]
                    ?.address ||
                  clientDetails?.email ||
                  ''
                const customerPhone =
                  client?.PhoneNumber ||
                  clientDetails?.generalStore?.[0]?.generalTelephone?.[0]
                    ?.number ||
                  ''
                setUpdateClientValues({
                  email: customerEmail,
                  phone: customerPhone,
                })
              }}
              className={styles.editIcon}
            />
          )}
        </div>
        <div className="product-sale-client-form">
          <div className="product-sale-client-form-element">
            <p>{t('SELL_BY_PROD_T.FIRST_LAST_NAME_LABEL')}</p>
            <input type="text" value={client?.FullName || ''} disabled />
          </div>
          <div className="product-sale-client-form-element">
            <p>{t('SELL_BY_PROD_T.IDENTIFICATION_LABEL')}</p>
            <input type="text" value={client?.NumberId || ''} disabled />
          </div>
          <div className="product-sale-client-form-element">
            <p>{t('SELL_BY_PROD_T.CLIENT_CODE_LABEL')}</p>
            <input type="text" value={client?.SystemCode || ''} disabled />
          </div>
          <div className="product-sale-client-form-element">
            <p>
              {isPhone
                ? t('SELL_BY_PROD_T.CELLPHONE_LABEL')
                : t('SELL_BY_PROD_T.PHONE_LABEL')}
            </p>
            <input
              type="text"
              maxLength={isPhone ? 8 : 7}
              onChange={e => handleChangePhone(e.target.value)}
              value={updateClientValues.phone || ''}
              disabled={!edit}
              className={errors.phone ? styles.errorInput : ''}
            />
            {errors.phone && <p className={styles.error}>{errors.phone}</p>}
          </div>
          <div className="product-sale-client-see-more">
            <p
              onClick={() => {
                setSeeMore(!seeMore)
              }}>
              {!seeMore ? t('SELL_BY_PROD_T.SEE_MORE_LABEL') : null}
            </p>
          </div>
          {seeMore && (
            <>
              <div className="product-sale-client-form-element">
                <p>{t('SELL_BY_PROD_T.MAIL_LABEL')}</p>
                <input
                  type="text"
                  onChange={e => handleChangeEmail(e.target.value)}
                  value={updateClientValues.email || ''}
                  disabled={!edit}
                  className={errors.email ? styles.errorInput : ''}
                />
                {errors.email && <p className={styles.error}>{errors.email}</p>}
              </div>
              <div className="product-sale-client-form-element">
                <p>{t('SELL_BY_PROD_T.CLIENT_TYPE')}</p>
                <input
                  type="text"
                  value={client?.CustomerTypeName || ''}
                  disabled
                />
              </div>
              <div className="product-sale-client-form-element">
                <p>{t('SELL_BY_PROD_T.PRICE_LIST_ASSIGNED')}</p>
                <input
                  type="text"
                  value={priceList?.Description || 'N/A'}
                  disabled
                />
              </div>

              <div>
                <p className="top_margin">Cuentas de crédito asociadas</p>
                <hr />

                {client?.CustomerId && creditAccounts.length > 0 ? (
                  <ul
                    style={{
                      fontSize: '14px',
                      marginTop: '1rem',
                      listStyleType: 'none',
                    }}>
                    {creditAccounts
                      .filter(account => account.CompanyId === companyId)
                      .map(item => (
                        <li
                          style={{ marginTop: '0.8rem', marginLeft: '0.5rem' }}
                          key={item.CreditAccountId}>
                          {item.CreditAccountNumber} -{' '}
                          {item.Description.toLowerCase().replace(
                            /\b\w/g,
                            char => char.toUpperCase()
                          )}
                        </li>
                      ))}
                  </ul>
                ) : client?.CustomerId && creditAccounts.length === 0 ? (
                  <p style={{ marginTop: '0.5rem', marginLeft: '1rem' }}>
                    No tiene cuentas de crédito asociadas
                  </p>
                ) : (
                  <p
                    style={{
                      marginTop: '0.5rem',
                      marginLeft: '1rem',
                      color: '#808080',
                    }}>
                    No hay información
                  </p>
                )}
                <hr />
              </div>

              {!edit && (
                <div className="product-sale-client-see-more">
                  <p
                    onClick={() => {
                      setSeeMore(!seeMore)
                    }}>
                    {t('SELL_BY_PROD_T.SEE_LESS')}{' '}
                  </p>
                </div>
              )}
              {edit && (
                <div className={styles.editButtonsWrapper}>
                  <button
                    onClick={() => {
                      setEdit(false)
                      setUpdateClientValues({
                        email: client?.Email || '',
                        phone: client?.PhoneNumber || '',
                      })
                      setErrors({ phone: '', email: '' })
                    }}
                    disabled={updateLoading}
                    className={styles.cancelButton}>
                    Cancelar
                  </button>
                  {updateLoading && (
                    <button
                      disabled
                      className={`${styles.saveButton} ${styles.loadingButton}`}>
                      <CircularProgress size={24} />
                    </button>
                  )}
                  {!updateLoading && (
                    <button
                      onClick={handleUpdateClient}
                      disabled={updateLoading || errors.phone || errors.email}
                      className={`${styles.saveButton} ${
                        errors.phone || errors.email ? styles.disabled : ''
                      }`}>
                      Guardar
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {client ? (
          <div className="product-sale-delivery-method">
            <MetodoEntrega
              insideForm={true}
              setSelectedAddress={direccion => setSelectedAddress(direccion)}
              setDireccion={direccion => {
                if (validateOnSuccessCallAddress) {
                  setValidateOnSuccessCallAddress(false)
                } else {
                  setValidateOnSuccessCallAddress(true)
                }
                setDireccion(direccion)
              }}
              handleMetodoEntrega={() => null}
              selectedAddress={selectedAddress}
              validateOnSuccessCallAddress={validateOnSuccessCallAddress}
              direccion={direccion}
              hasDefaultWarehouse={hasDefaultWarehouse}
              client={client}
            />
          </div>
        ) : null}
      </div>
      <AlertModal
        show={success}
        label={t('CREATE_CLIENT_T.CHANGES_SAVED')}
        onClick={() => setSuccess(false)}
      />
    </>
  )
}
