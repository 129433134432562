import React from 'react'
import Map from '../Map'

const FormDireccion = ({
  setSelectedAddress,
  setDireccion,
  handleMetodoEntrega,
  isModalScreen,
  defaultValues,
  onClose,
  showHeader,
  customerId,
}) => {
  return (
    <Map
      setSelectedAddress={setSelectedAddress}
      setDireccion={setDireccion}
      handleMetodoEntrega={handleMetodoEntrega}
      isModalScreen={isModalScreen}
      defaultValues={defaultValues}
      onClose={onClose}
      showHeader={showHeader}
      customerId={customerId}
    />
  )
}

export default FormDireccion
