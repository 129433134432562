import React from 'react'
import { Col } from 'react-bootstrap'
import { CheckCircleFill, Circle, GeoAltFill } from 'react-bootstrap-icons'

export const AddressOption = ({
  item,
  setLocation,
  location,
  showAddressDetail,
}) => {
  const [show, setShow] = React.useState(false)
  const isSelectedDirection =
    location?.AddressInfo?.AddressId === item?.AddressInfo?.AddressId
  const addressStreet =
    item?.AddressInfo?.Street || item?.AddressInfo?.AddressDetail
  const handleEditAddress = () => {
    showAddressDetail(item?.AddressInfo)
  }

  return (
    <div className="address-option-wrapper">
      {!!addressStreet && (
        <div
          className="tooltip"
          style={show ? { visibility: 'visible' } : {}}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}>
          {addressStreet}
          <button onClick={handleEditAddress} className="address-edit-button">
            Editar
          </button>
        </div>
      )}
      <button
        className={
          isSelectedDirection
            ? 'direction-button-style-active'
            : 'direction-button-style-inactive'
        }
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        onClick={() => {
          isSelectedDirection ? setLocation(null) : setLocation(item)
        }}>
        <Col className="direction-location-container col-6">
          <div>
            <GeoAltFill
              className={
                isSelectedDirection
                  ? 'pinpoint-location-active'
                  : 'pinpoint-location-inactive'
              }
            />
          </div>
          <div
            className={
              isSelectedDirection
                ? 'direction-text-style'
                : 'direction-text-style-inactive'
            }>
            {item.AddressInfo.Name || addressStreet}
          </div>
        </Col>
        <Col className="sell-order-column-width">
          {isSelectedDirection ? (
            <CheckCircleFill className={'check-circle-active'} />
          ) : (
            <Circle className={'check-circle-inactive'} />
          )}
        </Col>
      </button>
    </div>
  )
}
