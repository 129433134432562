import CommonSearchDocument from 'Components/CommonSearchDocument/CommonSearchDocument'
import { ROUTES, documentTypeId, statusFilter } from 'Utils/constants'
import { FormatMoneyTable } from 'Utils/functions'
import { t } from 'i18next'

function SearchSaleOrderWeb() {
  const setTableData = data => {
    const odv = data?.length
      ? data.map(orden => {
          const dispatchRequest = orden?.DispatchRequest?.map(
            dispatch => dispatch.DocumentNumber
          )
          const dispatchDocHeaderId = orden?.DispatchRequest?.map(
            dispatch => `${ROUTES.orderDispatch}${+dispatch.DocHeaderId}`
          )
          return {
            nombre_cliente: orden?.CustomerFullName || t('UNKNOWN'),
            sucursal: orden?.WarehouseDescription || t('UNKNOWN'),
            amount: `$ ${FormatMoneyTable(
              orden?.AmountWithTax,
              false,
              true,
              true
            )}`,
            numero_orden_venta: orden?.DocumentNumber,
            numero_orden_compra: orden?.OrderNumber
              ? orden?.OrderNumber
              : t('UNKNOWN'),
            numero_despacho: dispatchRequest?.length
              ? dispatchRequest
              : t('UNKNOWN'),
            numero_cotizacion: orden?.Quote[0]?.documentnumber || t('UNKNOWN'),
            fecha: orden?.CreatedDate.slice(0, 10),
            route: `${ROUTES.salesOrder}${orden?.DocHeaderId}`,
            estatus: orden?.Description,
            dispatchLinks: dispatchDocHeaderId?.length
              ? dispatchDocHeaderId
              : [],
          }
        })
      : []

    return odv
  }

  const objColumns = [
    {
      Header: t('SEARCH_QUOTATION_T.CLIENT_NAME'),
      accessor: 'nombre_cliente',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.WAREHOUSE_LABEL'),
      accessor: 'sucursal',
      hasFilter: true,
      filterType: 'warehouse',
    },
    {
      Header: t('SEARCH_QUOTATION_T.NUMBER_SALE_ORDER_LABEL'),
      accessor: 'numero_orden_venta',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.NUMBER_QUOTATION_LABEL'),
      accessor: 'numero_cotizacion',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.NUMBER_DESPACHO_LABEL'),
      accessor: 'numero_despacho',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.QUOTATION_AMOUNT_LABEL'),
      accessor: 'amount',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.DATE_QUOTATION_LABEL'),
      accessor: 'fecha',
      hasFilter: true,
      filterType: 'date',
    },
    {
      Header: t('SEARCH_QUOTATION_T.STATUS_LABEL'),
      accessor: 'estatus',
      hasFilter: true,
      filterType: 'status',
    },
  ]

  return CommonSearchDocument({
    title: 'SEARCH_QUOTATION_T.SALES_ORDER',
    objColumns,
    filterOptions: statusFilter.salesOrder,
    setTableData,
    placeholder: 'ACTION_TABLE_T.ADD_QUOTATION_PLACEHOLDER',
    documentTypeIdDocument: documentTypeId.ODV,
  })
}

export default SearchSaleOrderWeb
