import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import marker from '../../../Assets/Images/MetodoEntrega/geo-alt-fill.svg'

export const AddressOption = ({
  address,
  selectAddress,
  optionSelected,
  showEditAddress,
}) => {
  const [show, setShow] = React.useState(false)

  const addressStreet =
    address?.AddressInfo?.Street || address?.AddressInfo?.AddressDetail
  const isSelectedDirection =
    optionSelected?.AddressInfo?.AddressId === address.AddressInfo?.AddressId ||
    optionSelected?.Address_AddressId === address.AddressInfo?.AddressId

  const handleEditAddress = () => {
    showEditAddress(address?.AddressInfo)
  }

  return (
    <div className="address-option-wrapper">
      {!!addressStreet && (
        <div
          className="tooltip"
          style={show ? { visibility: 'visible' } : {}}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}>
          {addressStreet}
          <button onClick={handleEditAddress} className="address-edit-button">
            Editar
          </button>
        </div>
      )}
      <li
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        className={
          isSelectedDirection &&
          (address.AddressInfo?.Name !== undefined ||
            address.AddressInfo?.AddressDetail !== undefined)
            ? 'domicilio-option selected'
            : 'domicilio-option'
        }
        style={{ position: 'relative' }}>
        <div className="name-domicilio">
          <img src={marker} alt="marcador" />
          <label htmlFor={address?.AddressInfo?.AddressId}>
            {address.AddressInfo?.Name || address.AddressInfo?.AddressDetail}
          </label>
        </div>
        <div>
          <input
            type="radio"
            id={address?.AddressInfo?.AddressId}
            name="selected-address"
            onClick={() => {
              selectAddress(address)
            }}
          />
          <FontAwesomeIcon icon={faCheck} />
        </div>
      </li>
    </div>
  )
}
