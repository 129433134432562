import { format, parseISO } from 'date-fns'
import { t } from 'i18next'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const DocumentAddressDetails = ({
  showHomeDeliveryAddress,
  webOdvDetails,
}) => {
  const { sellOrderPostData } = useSelector(state => state.sellOrder)
  const useSelectorProducts = useSelector(state => state.cotizacion.QuoteHeader)
  const customerSavedLocations = useSelector(
    state => state.sellOrder.customerInfoObject
  )

  const isWebDocument =
    sellOrderPostData?.Data?.SalesDocumentInfo?.Origin === 'AB'
  const addressData = customerSavedLocations?.AddressList
  const busAddressId = webOdvDetails?.AddressId?.toLowerCase()

  const selectedAddressData = useMemo(() => {
    return addressData?.find(
      address => address?.AddressInfo?.AddressId?.toLowerCase() === busAddressId
    )?.AddressInfo
  }, [addressData, busAddressId])

  const phoneNumber =
    sellOrderPostData?.Data?.SalesDocumentInfo?.Address_PhoneAddress

  const formattedDate = webOdvDetails?.DeliveryDate
    ? format(parseISO(webOdvDetails?.DeliveryDate), 'dd/MM/yyyy')
    : null

  return (
    <div className="sell-order-info-gen-retiro">
      {showHomeDeliveryAddress && (
        <>
          <p className="sell-order-info-gen-text">
            {t('SELL_ORDER_T.DELIVERY_HOME')}
          </p>
          <p className="sell-order-info-gen-text">
            {t('SELL_ORDER_T.ADDRESS_LABEL', {
              address:
                useSelectorProducts?.QuoteAddressStreet ||
                useSelectorProducts?.QuoteAddressDetail,
            })}
          </p>
          {useSelectorProducts?.QuoteDistrictName && (
            <p className="sell-order-info-gen-text">
              {`${useSelectorProducts.CustomerAddress}`}
            </p>
          )}
          <p className="sell-order-info-gen-text">{`Alias: ${
            isWebDocument
              ? selectedAddressData?.Name ||
                useSelectorProducts?.Address_AddressName ||
                useSelectorProducts?.QuoteAddressDetail
              : useSelectorProducts?.Address_AddressName ||
                useSelectorProducts?.QuoteAddressDetail
          }`}</p>
          {phoneNumber && (
            <p className="sell-order-info-gen-text">
              {t('DISPATCH_ORDER_T.PHONE_ADDRESS', {
                phone: phoneNumber,
              })}
            </p>
          )}
          {isWebDocument && formattedDate && (
            <p className="sell-order-info-gen-text">
              Fecha promesa: {formattedDate}
            </p>
          )}
        </>
      )}
      {!showHomeDeliveryAddress && (
        <>
          <p className="sell-order-info-gen-text">
            {t('SELL_ORDER_T.BRANCH_RECOLECTION_LABEL')}
          </p>
          <p className="sell-order-info-gen-text">
            {t('SELL_ORDER_T.ADDRESS_LABEL', {
              address: useSelectorProducts.WarehouseAddress,
            })}
          </p>
          <p className="sell-order-info-gen-text">
            {useSelectorProducts.WarehouseDescription}
          </p>
          {isWebDocument && formattedDate && (
            <p className="sell-order-info-gen-text">
              Fecha promesa: {formattedDate}
            </p>
          )}
        </>
      )}
    </div>
  )
}
