import React from 'react'
import { GeoAltFill } from 'react-bootstrap-icons'

export const AddressOption = ({ address, showAddressDetail }) => {
  const [show, setShow] = React.useState(false)
  const addressStreet =
    address?.AddressInfo?.Street || address?.AddressInfo?.AddressDetail
  const handleEditAddress = () => {
    showAddressDetail(address?.AddressInfo)
  }

  return (
    <div style={{ position: 'relative' }}>
      {!!addressStreet && (
        <div
          className="tooltip"
          style={show ? { visibility: 'visible' } : {}}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}>
          {addressStreet}
          <button onClick={handleEditAddress} className="address-edit-button">
            Editar
          </button>
        </div>
      )}
      <div
        className="customer-info-sidebar-address"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}>
        <div>
          <GeoAltFill style={{ color: 'red' }} />
        </div>
        <p>{address.AddressInfo.Name || addressStreet}</p>
      </div>
    </div>
  )
}
