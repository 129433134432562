export const hasValidData = product => {
  return product?.amount > 0 && product?.price > 0
}

/**
 * @param {Array} products - Lista de productos
 * @returns {Array} - Lista de productos sin duplicados
 *
 * @example
 * const products = [
 *  { productId: 1, amount: 1, price: 10 },
 *  { productId: 1, amount: 2, price: 0 },
 * ]
 *
 * removeDuplicateProducts(products) // Output [ { productId: 1, amount: 1, price: 10 } ]
 */
export const removeDuplicateProducts = products => {
  const productMap = new Map()

  products.forEach(product => {
    const existingProduct = productMap.get(product.productId)

    if (!existingProduct) {
      productMap.set(product.productId, product)
    } else {
      const currentIsBetter = hasValidData(product)
      const existingIsBetter = hasValidData(existingProduct)

      if (currentIsBetter && !existingIsBetter) {
        productMap.set(product.productId, product)
      }
    }
  })

  return Array.from(productMap.values())
}
