/* eslint-disable no-useless-escape */
import { emptyCart, priceListAddToCart } from 'redux/actions/cartActions'
import { getPdfCotizacionAction } from 'redux/actions/cotizacionActions'
import { getPriceInventory } from 'redux/actions/priceProductsAction'
import {
  constFormatterCurrency,
  documentTypeId,
  salesRoles,
  searchTitle,
} from './constants'

export const validateRoles = (userRoles, role) => {
  return userRoles.some(roleObject => roleObject.RoleName === role)
}

export const hasChangePriceApprove = productList => {
  const hasProductsDiscount = productList?.filter(
    product => product?.NegotiatedReasonId
  )

  return hasProductsDiscount.length > 0
    ? !hasProductsDiscount?.every(
        product => product?.AuthorizedPriceBy && product?.AuthorizedPriceByName
      )
    : false
}
export const FormatMoney = (number, rounded = false, decimal = 2) => {
  let parseNumber

  if (rounded) {
    parseNumber =
      Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal)
  } else {
    parseNumber =
      Math.round(number * Math.pow(10, decimal + 2)) / Math.pow(10, decimal + 2) // Redondear solo si rounded es true
  }

  const integerPart = Math.floor(parseNumber)

  let decimalPart = ''
  const decimalStr = parseNumber.toString().split('.')[1]
  if (decimalStr) {
    decimalPart = decimalStr.substring(0, Math.min(decimalStr.length, decimal))
  }

  // Si los decimales son todos ceros, mostrar solo la parte entera
  if (parseInt(decimalPart) === 0 && decimal > 0) {
    return integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // Completar con ceros a la derecha si faltan decimales
  if (
    (decimalPart.length === 1 && decimal === 2) ||
    (decimalPart.length === 3 && decimal === 4)
  ) {
    while (decimalPart.length < decimal) {
      decimalPart += '0'
    }
  }

  let formattedNumber

  if (decimalPart) {
    formattedNumber = `${integerPart
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${decimalPart}`
  } else {
    formattedNumber = integerPart
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return formattedNumber
}

export const FormatMoneyTable = (
  number,
  rounded = false,
  decimal = 2,
  money
) => {
  if (
    number === null ||
    number === undefined ||
    isNaN(number) ||
    number === 0
  ) {
    if (money) {
      return '0.00'
    } else {
      return '0'
    }
  }
  let parseNumber

  if (rounded) {
    parseNumber =
      Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal)
  } else {
    parseNumber =
      Math.round(number * Math.pow(10, decimal + 2)) / Math.pow(10, decimal + 2) // Redondear solo si rounded es true
  }

  const integerPart = Math.floor(parseNumber)

  let decimalPart = ''
  const decimalStr = parseNumber.toString().split('.')[1]
  if (decimalStr) {
    decimalPart = decimalStr.substring(0, Math.min(decimalStr.length, decimal))
  }

  // Si los decimales son todos ceros, mostrar solo la parte entera
  if (parseInt(decimalPart) === 0 && decimal > 0) {
    return integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // Completar con ceros a la derecha si faltan decimales
  if (
    (decimalPart.length === 1 && decimal === 2) ||
    (decimalPart.length === 3 && decimal === 4)
  ) {
    while (decimalPart.length < decimal) {
      decimalPart += '0'
    }
  }

  let formattedNumber

  if (decimalPart) {
    formattedNumber = `${integerPart
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${decimalPart}`
  } else {
    formattedNumber = integerPart
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  if (money) {
    if (formattedNumber.indexOf('.') === -1) {
      formattedNumber += '.00'
    } else {
      const decimalPart = formattedNumber.split('.')[1]

      if (decimalPart.length === 1) {
        formattedNumber += '0'
      }
    }
  }

  return formattedNumber
}

export const formatterCurrency = function (value) {
  // check if value is a number or string parse it to float
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  if (value) {
    return value.toFixed(2).toString().replace(constFormatterCurrency, ',')
  }
  if (value === 0) {
    return '0.00'
  }
  return value
}

export const toTitleCase = str => {
  const data = str?.replace(searchTitle, function (txt) {
    return txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase()
  })
  return data
}

export const formatDate = dateString => {
  const date = new Date(dateString)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString().substr(-2)
  const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const amPm = date.getHours() >= 12 ? 'pm' : 'am'

  return `${day}/${month}/${year} ${hours}:${minutes} ${amPm}`
}

/**
 * `getMarginTotal` takes in a price, cost, and quantity and returns the total margin
 * @param price - the price of the item
 * @param cost - the cost of the item
 * @param qty - the quantity of the item
 */

const formula = (totalPrice, totalCost) => {
  const totalMargin = ((totalPrice - totalCost) / totalPrice) * 100
  return totalMargin
}
export const getMarginTotal = (price = 0, cost = 0, qty = 1) => {
  const totalPrice = price * qty
  const totalCost = cost * qty
  return formula(totalPrice, totalCost)
}

export const marginTotal = (costs, total) => {
  return formula(total, costs)
}

export const isCallCenterUser = userRoles => {
  return userRoles.some(
    roleObject => roleObject.RoleName === salesRoles.callCenter
  )
}

export const isGerentsStore = userRoles => {
  return userRoles.some(
    roleObject =>
      roleObject.RoleName ===
      (salesRoles.gerenteTienda || salesRoles.supervisorTienda)
  )
}

export const generateColorStatus = (
  status,
  pendingStatusDocument,
  processStatusDocument,
  closeStatusDocument,
  colorsStatus
) => {
  const statusMappings = {
    pending: pendingStatusDocument,
    process: processStatusDocument,
    close: closeStatusDocument,
  }

  for (const color in statusMappings) {
    if (Object.values(statusMappings[color]).includes(status)) {
      return colorsStatus[color]
    }
  }

  return colorsStatus.default
}

export const refreshShoppingCart = async ({ cart, customerId, dispatch }) => {
  if (cart.length) {
    const productList = cart
    dispatch(emptyCart())
    const productIds = productList.map(product => product.ProductId)
    const inventoryProducts = await dispatch(getPriceInventory(productIds))

    const newProductList = productList.map(product => {
      const productInCart = inventoryProducts.find(
        productCart => productCart.ProductId === product.ProductId
      )
      return {
        ...product,
        Cost: productInCart?.Cost || productInCart?.AvgCost || 0.0,
      }
    })
    dispatch(priceListAddToCart(newProductList, customerId))
  }
}

export function validateRepeatedProduct(product, cart, dispatch, customerId) {
  const existingProductIndex = cart.findIndex(
    item => item.ProductId === product.ProductId
  )

  if (existingProductIndex !== -1) {
    const [integerPart, decimalPart] =
      cart[existingProductIndex].Quantity.toString().split('.')
    const incrementedInteger = parseInt(integerPart) + 1
    const newQuantity = decimalPart
      ? `${incrementedInteger}.${decimalPart}`
      : `${incrementedInteger}`
    cart[existingProductIndex].Quantity = newQuantity
  } else {
    if (product.BasePrice > product.FinalPrice) {
      product.hasDiscount = true
    }

    cart.push(product)
  }

  const updatedProduct = cart[existingProductIndex] || product
  return dispatch(priceListAddToCart([updatedProduct], customerId))
}

export const updateHiddenColumns = (newColumns, columns) => {
  // eslint-disable-next-line array-callback-return
  const enabledHiddenColumns = columns.filter(column => {
    if (column.hideEnabled) {
      return column.Header
    }
  })
  // if newColumns is empty, then show all columns false
  const newVisibleColumns = enabledHiddenColumns.map(column => {
    return {
      ColumnName: column.Header,
      Status: !!newColumns.includes(column.Header),
    }
  })
  return newVisibleColumns
}

/**
 * The function takes a date input and returns it in the format yyyy-mm-dd.
 * @param date - The `date` parameter is a string or a Date object representing the date for which you
 * want to get the formatted date string in the format "yyyy-mm-dd".
 * @returns The function `getDateFormatted` returns a string representing the current date in the
 * format "yyyy-mm-dd".
 */
export const getDateFormatted = date => {
  // get current date in format yyyy-mm-dd
  const today = new Date(date)
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
  const yyyy = today.getFullYear()
  return yyyy + '-' + mm + '-' + dd
}

export const formatedDate = date => {
  return new Date(date)
}

export const downloadPdfFuntion = async (
  Whatsapp = false,
  header,
  dispatch,
  setShowFailMessageModal,
  documentType
) => {
  const payload = {
    DocumentTypeId: documentType || documentTypeId.QUOTATION,
    DocHeaderId: header,
  }
  try {
    const Data = await dispatch(getPdfCotizacionAction(payload))
    if (Data && Data?.BlobResponse) {
      let url
      if (Whatsapp) {
        // open Api whatsapp with url
        url = `https://api.whatsapp.com/send?text=${Data?.BlobResponse?.FileUrl}`
      } else {
        url = Data?.BlobResponse?.FileUrl
      }
      window.open(url, '_blank')
    } else {
      throw new Error(Data?.toString())
    }
  } catch (error) {
    console.log('ERRORASO: ', error)
    setShowFailMessageModal(true)
    setShowFailMessageModal(true)
  }
}

export const generateRegularExpression = (decimalLimit, RegExp) => {
  return new RegExp(`^\\d{1,3}(,\\d{3})*(\.\\d{1,${decimalLimit}})?$`)
}

export const handleFilterList = ({
  list,
  setFilterList,
  mapKey,
  setCurrentPage,
  setLastIndex,
  setFirstIndex,
}) => {
  console.log('Puues', list)
  if (!list) {
    setFilterList([])
  } else {
    const mappedList = list.map(option => ({ [mapKey]: option }))
    setFilterList(mappedList)
  }

  setCurrentPage(1)
  setLastIndex(3)
  setFirstIndex(0)
}

export const fetchPriceInventory = async ({
  hits,
  customerId,
  warehouse,
  companyCode,
  companyId,
  getPrices,
  t,
}) => {
  const PriceInventoryData = {
    CompanyId: companyId,
    CompanyCode: companyCode,
    WarehouseId: warehouse,
    ProductIds: hits?.map(item => item.productId || item.ProductId) || [],
    PriceListId: null,
    UoMId: null,
  }

  try {
    const priceData = await getPrices(PriceInventoryData)

    const priceDataMap = {}
    priceData.forEach(product => {
      priceDataMap[product.ProductId] = product
    })

    const productArrayWithPrice = hits.map(item => ({
      ...item,
      ...priceDataMap[item.productId || item.ProductId],
    }))

    return productArrayWithPrice
  } catch (error) {
    throw (t('PRODUCTS_SEARCH_T.ERROR_FILTERING_PRICES_LABEL'), error)
  }
}
