import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { formatDate } from 'Utils/functions'

export const ModifiedBySection = () => {
  const param = useParams()
  const useSelectorQuoteObject = useSelector(state => state.cotizacion)

  const documentDate = useSelectorQuoteObject?.QuoteHeader?.DocumentDueDate
  const salesAgent = useSelectorQuoteObject?.QuoteHeader?.SalesAgentFullName
  const isCreated = param?.isPreorderValidatedOnRoute === 'created'

  const showContent = isCreated && documentDate && salesAgent

  if (!showContent) return null

  return (
    <div style={{ display: 'flex', marginTop: '16px' }}>
      <p style={{ fontSize: '12px' }}>
        Fecha de última modificación: {formatDate(documentDate)}
        {'  |  '}
        Modificado por: {salesAgent}
      </p>
    </div>
  )
}
