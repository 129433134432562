import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { searchSalesDocumentDetailService } from 'redux/services/cotizacionServices'
import { documentTypeId } from 'Utils/constants'
import { FormatMoney } from 'Utils/functions'

const paymentName = {
  yappy: 'Yappy:',
  comercial: 'Cuenta a crédito',
  crédito: 'Tarjeta',
  puntos: 'Puntos Gordos:',
  regalo: 'Tarjeta de Regalo:',
  cmf: 'CMF',
  ach: 'Transferencia ACH:',
}

export const PaymentType = () => {
  const { sellOrderPostData, isCommercialCreditSelected } = useSelector(
    state => state.sellOrder
  )

  const [payments, setPayments] = React.useState([])

  const {
    Amount,
    Tax,
    AmountWithTax,
    DocHeaderId,
    Origin,
    CreditAccountNumber,
  } = sellOrderPostData?.Data?.SalesDocumentInfo || {}

  const isWebDocument = Origin === 'AB'
  const showPayments = isWebDocument ? !!payments?.length : !!AmountWithTax
  const creditAccountNumber =
    CreditAccountNumber ||
    isCommercialCreditSelected?.accountItemSelected?.CreditAccountNumber

  const getOdvPayments = async () => {
    try {
      const response = await searchSalesDocumentDetailService({
        DocumentTypeId: documentTypeId.ODV,
        DocumentHeaderId: DocHeaderId,
        DocumentNumber: null,
        PageNumber: 1,
        PageSize: 1,
      })
      const payments = response?.data?.Data?.Payments
      setPayments(payments)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (DocHeaderId && Origin === 'AB') {
      getOdvPayments()
    }
  }, [DocHeaderId, Origin])

  return (
    <div style={{ display: 'flex', gap: '16px' }}>
      <div style={{ ...styles.containerStyles, width: '60%' }}>
        {showPayments && (
          <>
            <p style={{ fontSize: '14px', fontWeight: '600' }}>
              Métodos de pago
            </p>
            {!isWebDocument && creditAccountNumber && (
              <p style={{ fontSize: '14px' }}>
                Crédito Comercial {creditAccountNumber}: $
                {FormatMoney(AmountWithTax || 0)}
              </p>
            )}
            {!isWebDocument && !creditAccountNumber && (
              <p style={{ fontSize: '14px' }}>
                Contado: ${FormatMoney(AmountWithTax || 0)}
              </p>
            )}
            {payments?.map((payment, index) => {
              const coincidence = Object.keys(paymentName).find(key =>
                payment?.PaymentName?.toLowerCase().includes(key)
              )
              const isCreditCard =
                payment?.PaymentName?.toLowerCase().includes('crédito')
              const isCreditAccount =
                payment?.PaymentName?.toLowerCase().includes('comercial')
              const isCmf = payment?.PaymentName?.toLowerCase().includes('cmf')
              const creditCard = isCmf
                ? payment?.PaymentMethodDetails?.CMF?.TDCSales?.UDTRows?.[0]?.UserFieldsList?.find(
                    item => item?.FieldName === 'U_CreditCard'
                  )?.FieldValue
                : isCreditCard
                ? payment?.PaymentMethodDetails?.PaymentAttributes?.find(item =>
                    item?.PaymentTypeAttributeId?.includes('Ultimo4Digitos')
                  )?.CustomInformation
                : null

              const lastFourDigits =
                typeof creditCard === 'string' ? creditCard?.slice(-4) : ''

              return (
                <p key={index} style={{ fontSize: '14px' }}>
                  {paymentName[coincidence] || 'Otro:'}
                  {isCmf
                    ? `: ${lastFourDigits} Cuota fija: $`
                    : isCreditAccount
                    ? `: $`
                    : isCreditCard
                    ? `: ${lastFourDigits} $`
                    : ' $'}
                  {FormatMoney(payment?.PaymentAmount || 0, false, 2, true)}
                </p>
              )
            })}
          </>
        )}
      </div>
      <div style={{ ...styles.containerStyles, width: '40%' }}>
        <div style={styles.priceStyles}>
          <h3 style={{ fontSize: '14px' }}>Sub Total</h3>
          <p style={{ fontSize: '14px' }}>
            ${FormatMoney(Amount || 0, false, 2, true)}
          </p>
        </div>
        <div style={styles.priceStyles}>
          <h3 style={{ fontSize: '14px' }}>ITBMS</h3>
          <p style={{ fontSize: '14px' }}>
            ${FormatMoney(Tax || 0, false, 2, true)}
          </p>
        </div>
        <div style={styles.priceStyles}>
          <h3 style={{ fontSize: '14px', fontWeight: '600' }}>Total</h3>
          <p style={{ fontSize: '14px', fontWeight: '600' }}>
            ${FormatMoney(AmountWithTax || 0, false, 2, true)}
          </p>
        </div>
      </div>
    </div>
  )
}

const styles = {
  containerStyles: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    background: '#FFFFFF',
    borderRadius: '5px',
    marginTop: '15px',
    boxShadow: '0px 1px 4px rgba(21, 34, 50, 0.14)',
    padding: '16px 53px',
    height: 'auto',
  },
  priceStyles: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '8px',
    width: '100%',
  },
}
