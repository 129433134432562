import { Modal } from '@mui/material'
import React from 'react'
import Mapa from 'Components/Map'

export const EditAddressModal = ({ addressDetails, onClose, onSave }) => {
  return (
    <Modal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: window.innerWidth - 491,
        alignItems: 'center',
        zIndex: 999999999999999,
      }}
      onClose={onClose}
      open={true}>
      <>
        <Mapa
          setSelectedAddress={() => {}}
          setDireccion={() => {}}
          handleMetodoEntrega={onSave}
          isModalScreen={true}
          defaultValues={addressDetails}
          onClose={onClose}
        />
      </>
    </Modal>
  )
}
