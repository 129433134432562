import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MiniLoading from '../MiniLoading'
import no from '../../Assets/Images/Login/no.svg'
import Journal from '../../Assets/Images/Clients/journal.svg'
import FlatPoints from '../../Assets/Images/Clients/flat_points.svg'
import './customerInfo.css'
import { t } from 'i18next'
import { getCustomerById } from 'redux/actions/clientsActions'
import { searchSalesDocuments } from 'redux/actions/cotizacionActions'
import { getPuntosGordosByClient } from 'redux/actions/puntosGordosActions'
import { customerTypeId, documentTypeId } from 'Utils/constants'
import JournaCredit from '../../Assets/Images/Cotizacion/book-money.png'
import { CustomerContext } from 'state/context/CustomerFormContext'
import { AddressOption } from 'Components/ActionTable/components/AddressOption'

function CustomerInfo({
  handleOpen,
  open,
  customerId,
  setCustomerId,
  inModal,
  handleOpenEditAddress,
  refreshCustomerInfo,
}) {
  const ADDRESS_PER_PAGE = 6
  const {
    getCreditAccounts,
    hasCreditAccounts,
    showCreditAccounts,
    setShowCreditAccounts,
  } = CustomerContext()
  const openRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [customer, setCustomer] = useState(null)
  const [addressNumber, setAddressNumber] = useState(ADDRESS_PER_PAGE)
  const [puntosGordos, setPuntosGordos] = useState(0)
  const [puntosGordosAmount, setPuntosGordosAmount] = useState(0)
  const [numberOfQuotes, setNumberOfQuotes] = useState(0)

  const dispatch = useDispatch()

  const companyId = useSelector(state => state.company.CompanyId)

  const hasMoreAddress =
    customer?.AddressList?.length > ADDRESS_PER_PAGE &&
    addressNumber < customer?.AddressList?.length

  // CLOSE
  const closeBar = () => {
    handleOpen(false)
  }

  // FETCH CUSTOMER INFO
  const fetchCustomer = async () => {
    setLoading(true)
    const customerData = await dispatch(getCustomerById(customerId, true))
    if (customerData) {
      setCustomer(customerData)
      fetchPuntosGordos(customerData.CustomerInfo?.CustomerId)
      fetchCotizaciones(customerData.CustomerInfo?.CustomerId)
    }
    setLoading(false)
  }

  const fetchCotizaciones = async id => {
    const payload = {
      CompanyId: companyId,
      DocumentTypeId: documentTypeId.QUOTATION,
      customerId: id,
      PageNumber: 1,
      PageSize: 1,
    }
    const cotizacionByClient = await dispatch(searchSalesDocuments(payload))
    if (cotizacionByClient.length > 0) {
      setNumberOfQuotes(cotizacionByClient[0].TotalRegisters)
    }
  }

  // FETCH CUSTOMER PUNTOS GORDOS
  const fetchPuntosGordos = async id => {
    const puntosGordos = await dispatch(getPuntosGordosByClient(id))
    if (puntosGordos) {
      setPuntosGordos(puntosGordos.points)
      setPuntosGordosAmount(puntosGordos.amount)
    }
  }

  useEffect(() => {
    if (open) {
      fetchCustomer()
      setAddressNumber(ADDRESS_PER_PAGE)
    }
  }, [customerId, companyId, open])

  useEffect(() => {
    open && getCreditAccounts(customerId)
  }, [customerId])

  useEffect(() => {
    if (refreshCustomerInfo) {
      fetchCustomer()
    }
  }, [refreshCustomerInfo])

  return (
    <>
      <div
        className={
          open ? 'customer-info-navbar' : 'customer-info-navbar-closed'
        }
        ref={openRef}>
        {open && (
          <>
            <div className="close-salesman">
              <h2 className="salesman-title">
                {t('CUSTOMER_INFO_T.CLIENT_DETAIL_LABEL')}
              </h2>
              <img
                src={no}
                alt="close"
                onClick={() => {
                  closeBar()
                  if (typeof setCustomerId === 'function') setCustomerId(null)
                  setCustomer(null)
                }}
              />
            </div>
            <hr />
            {loading ? (
              <MiniLoading />
            ) : (
              <div className="customer-info-sidebar-container">
                <div className="customer-info-sidebar-element">
                  <h2>{t('CUSTOMER_INFO_T.FIRST_LAST_NAME_LABEL')}</h2>
                  <p>
                    {customer?.CustomerInfo?.FullName ||
                      customer?.CustomerInfo?.LegalName}
                  </p>
                </div>
                <div className="customer-info-sidebar-element">
                  <h2>{t('CUSTOMER_INFO_T.IDENTIFICATION_LABEL')}</h2>
                  <p>
                    {customer?.CustomerInfo?.IDNumber ||
                      customer?.CustomerInfo?.RUC}
                  </p>
                </div>

                <div className="customer-info-sidebar-element">
                  <h2>{t('CUSTOMER_INFO_T.CLIENT_CODE')}</h2>
                  <p>{customer?.CustomerInfo?.SystemCode}</p>
                </div>

                <div className="customer-info-sidebar-element">
                  <h2>{t('CUSTOMER_INFO_T.PHONE_LABEL')}</h2>
                  <p>
                    {customer?.CustomerInfo?.Telephone ||
                      t('CUSTOMER_INFO_T.NO_INFO_LABEL')}
                  </p>
                </div>

                <div className="customer-info-sidebar-element">
                  <h2>{t('CUSTOMER_INFO_T.MAIL_LABEL')}</h2>
                  <p>
                    {customer?.CustomerInfo?.Email ||
                      customer?.AdministrativeStoreInfo?.EmailList?.[0]
                        ?.EmailInfo?.Address ||
                      t('CUSTOMER_INFO_T.NO_INFO_LABEL')}
                  </p>
                </div>

                <div className="customer-info-sidebar-element">
                  <h2>{t('CUSTOMER_INFO_T.CLIENT_LABEL_TYPE')}</h2>
                  <p>{customer?.CustomerType?.Description}</p>
                </div>

                <div className="customer-info-sidebar-element">
                  <h2>{t('CUSTOMER_INFO_T.ASSIGNED_PRICE_LIST')}</h2>
                  <p>
                    {customer?.CustomerInfo?.PriceList
                      ? customer.CustomerInfo?.PriceList.filter(
                          price => price.DefaultList
                        )[0]?.PriceListName ||
                        customer.CustomerInfo?.PriceList[0]?.PriceListName ||
                        t('CUSTOMER_INFO_T.NO_INFO_LABEL')
                      : t('CUSTOMER_INFO_T.NO_INFO_LABEL')}
                  </p>
                </div>

                <div className="customer-info-sidebar-element">
                  <h2>{t('CUSTOMER_INFO_T.CLIENT_DIRECTIONS_LABEL')}</h2>

                  {customer &&
                  customer.CustomerType?.CustomerTypeId ===
                    customerTypeId.natural ? (
                    <>
                      {!customer?.AddressList && (
                        <p>{t('CUSTOMER_INFO_T.NO_INFO_LABEL')}</p>
                      )}
                      <div className="customer-info-sidebar-addresses">
                        {customer?.AddressList?.length > 0 &&
                          customer?.AddressList.slice(0, addressNumber).map(
                            address => (
                              <AddressOption
                                key={address.AddressInfo?.AddressId}
                                address={address}
                                showAddressDetail={handleOpenEditAddress}
                              />
                            )
                          )}
                      </div>
                    </>
                  ) : (
                    <>
                      {!customer?.AdministrativeStoreInfo?.AddressList
                        ?.length && <p>{t('CUSTOMER_INFO_T.NO_INFO_LABEL')}</p>}
                      <div className="customer-info-sidebar-addresses">
                        {customer?.AdministrativeStoreInfo?.AddressList
                          ?.length > 0 &&
                          customer?.AdministrativeStoreInfo?.AddressList.slice(
                            0,
                            addressNumber
                          ).map(address => (
                            <AddressOption
                              key={address.AddressInfo?.AddressId}
                              address={address}
                              showAddressDetail={handleOpenEditAddress}
                            />
                          ))}
                      </div>
                    </>
                  )}
                </div>
                {hasMoreAddress && (
                  <div className="see-more-customer">
                    <p
                      onClick={() =>
                        setAddressNumber(addressNumber + ADDRESS_PER_PAGE)
                      }>
                      {t('CUSTOMER_INFO_T.SEE_MORE_LABEL')}
                    </p>
                  </div>
                )}
                <hr />
                <div className="customer-info-extra">
                  <div className="customer-info-extra-element">
                    <img src={FlatPoints} alt="flat_points" />
                    <p>
                      {puntosGordos.toLocaleString()} = $
                      {puntosGordosAmount.toFixed(2)}
                    </p>
                  </div>
                  {customer?.CustomerInfo?.TierDescription ? (
                    <div className="customer-info-extra-element">
                      <img
                        src={customer?.CustomerInfo?.TierImageUrl}
                        alt="red_pro"
                        width={'90px'}
                        height={'82px'}
                      />
                      <p>
                        {t('CUSTOMER_INFO_T.REDPRO')}{' '}
                        {customer?.CustomerInfo?.TierDescription}
                      </p>
                    </div>
                  ) : null}
                  <div className="customer-info-extra-element">
                    <img src={Journal} alt="journal" />
                    <p>{numberOfQuotes}</p>
                  </div>
                  {hasCreditAccounts && (
                    <button
                      onClick={() => setShowCreditAccounts(!showCreditAccounts)}
                      className="customer-info-extra-element actionable">
                      <img
                        className="journal-icons"
                        src={JournaCredit}
                        alt="journal"
                      />
                      <p>Cuentas crédito</p>
                    </button>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default CustomerInfo
