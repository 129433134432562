/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import './crearCliente.css'
import { VAContext } from '../../../Context/VAContext'

import MiniLoading from '../../../Components/MiniLoading'

import moment from 'moment'
import { t } from 'i18next'
import {
  deleteListPricesAction,
  getCountriesAction,
  getCustomerById,
  getDocumentTypesAction,
  getListPricesByCustomerAction,
  getTelephoneTypesAction,
  registerCustomerAction,
  saveListPricesAction,
  updateCustomerAction,
} from 'redux/actions/clientsActions'
import { customerTypeId, documentTypeIds, VAPermissions } from 'Utils/constants'
import { TabNatural } from './components/TabNatural'
import { TabLegal } from './components/TabLegal/TabLegal'
import { AlertModal } from './components/AlertModal/AlertModal'
import { EmailModal } from './components/EmailModal/EmailModal'
import { useFormLogic } from './hooks/useFormLogic'
import { getPriceListPayload } from './utils/getPriceListPayload'
import { isCallCenterUser } from 'Utils/functions'
import useValidatePermission from 'Hooks/useValidatePermission'
const initialAddressState = {
  country: false,
  province: false,
  district: false,
  township: false,
  street: false,
  detail: false,
}

const CrearCliente = ({ handleClose, needAutoclose }) => {
  const dispatch = useDispatch()
  const loginInfo = useSelector(state => state.login?.user?.AuthenticationInfo)
  const isCallCenter = isCallCenterUser(loginInfo.UserRoles)

  const createComercialClient = useValidatePermission(
    VAPermissions.createComercialClient
  )

  const customerNaturalId = customerTypeId.natural
  const customerLegalId = customerTypeId.legal

  const [tipoTelefono, setTipoTelefono] = useState([])

  const [documentType, setDocumentType] = useState([])
  const [valueState, setValueState] = useState([])
  const [valueEditState, setValueEditState] = useState([])

  const [mapKey, setMapKey] = useState(null)

  const [typeClient, setTypeClient] = useState(customerNaturalId)
  const [successModal, setSuccessModal] = useState(false)
  const [successModalUpdate, setSuccessModalUpdate] = useState(false)
  const [tabNatural, setTabNatural] = useState(true)
  const [tabJuridica, setTabJuridica] = useState(true)

  const { clientSelected } = useContext(VAContext)

  const [loader, setLoader] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false)
  const [saveResultMessage, setSaveResultMessage] = useState('')

  const [hasAddress, setHasAddress] = useState(initialAddressState)
  const [countryCode, setCountryCode] = useState('')
  const [provinceCode, setProvinceCode] = useState('')
  const [districtCode, setDistrictCode] = useState('')
  const [townshipCode, setTownshipCode] = useState('')

  const [street, setStreet] = useState('')
  const [name, setName] = useState('')

  const [valueStateSelectCustomerGroup, setValueStateSelectCustomerGroup] =
    useState({ Description: '', customerGroupId: '' })
  const [valueStateSelect, setValueStateSelect] = useState({
    Description: '',
    genderId: '',
  })
  const [valueStateSelectCustomer, setValueStateSelectCustomer] = useState(null)

  const clearAddress = () => {
    const clearState = {
      country: false,
      province: false,
      district: false,
      township: false,
      street: false,
      detail: false,
    }
    setHasAddress(clearState)
    setStreet('')
    setName('')
    setCountryCode('')
    setProvinceCode('')
    setDistrictCode('')
    setTownshipCode('')
  }

  const validateAndSetAddress = () => {
    if (
      (hasAddress.country &&
        hasAddress.province &&
        hasAddress.district &&
        hasAddress.township &&
        hasAddress.street &&
        hasAddress.detail) ||
      clientSelected.edit
    ) {
      return {
        AddressId: null,
        addressTypeId: '90ACBCF4-BE83-4F03-8B5E-5760FC92B59B',
        name: name?.trim(), // Alias for MC
        CountryId: countryCode,
        ProvinceId: provinceCode,
        DistrictId: districtCode,
        TownshipId: townshipCode,
        NeighborhoodId: null,
        Street: street?.trim(),
        BuildingTypeId: '18272DEA-60DB-4F71-B292-1FD44E3A2B30',
        AddressDetail: street?.trim(),
      }
    }
    return null
  }

  const saveClient = async customer => {
    if (
      formikNatural.values.documentTypeId.toLowerCase() !==
        documentTypeIds.natural.NT &&
      typeClient !== customerLegalId
    ) {
      setLoadingSave(true)
      formikNatural.setSubmitting(true)

      const response = await dispatch(registerCustomerAction({ ...customer }))
      if (response?.data?.Status?.Code === 200) {
        if (valueState?.length) {
          const listPriceIds = valueState.map(list => list.PriceListId)
          const payload = getPriceListPayload(
            listPriceIds,
            response?.data?.Data?.CustomerInfo?.CustomerId
          )
          const res = await dispatch(saveListPricesAction(payload))
          if (!res.Message) {
            formikNatural.setSubmitting(false)

            setSaveResultMessage(t('CREATE_CLIENT_T.CLIENT_CREATED'))
          } else {
            formikNatural.setSubmitting(false)

            setSaveResultMessage(res.Message)
            formikLegal.setSubmitting(false)
          }
        } else {
          formikNatural.setSubmitting(false)

          setSaveResultMessage(t('CREATE_CLIENT_T.CLIENT_CREATED'))
        }
      } else {
        console.error('Error :', JSON.stringify(response))
        setLoadingSave(false)

        setSaveResultMessage({ message: response.Message, code: response.Code })
        formikNatural.setSubmitting(false)
      }

      setSuccessModal(true)
      setLoadingSave(false)
    } else if (
      (typeClient === customerNaturalId &&
        formikNatural.values.documentTypeId.toLowerCase() ===
          documentTypeIds.natural.NT) ||
      typeClient === customerLegalId
    ) {
      formikNatural.setSubmitting(true)

      setLoadingSave(true)

      const response = await dispatch(registerCustomerAction({ ...customer }))

      if (response?.data?.Status?.Code === 200) {
        formikNatural.setSubmitting(false)
        setSaveResultMessage(t('CREATE_CLIENT_T.CLIENT_CREATED'))

        if (valueState?.length) {
          const listPriceIds = valueState.map(list => list.PriceListId)
          const payload = getPriceListPayload(
            listPriceIds,
            response?.data?.Data?.CustomerInfo?.CustomerId
          )
          const res = await dispatch(saveListPricesAction(payload))
          if (!res.Message) {
            formikNatural.setSubmitting(false)

            setSaveResultMessage(t('CREATE_CLIENT_T.CLIENT_CREATED'))
          } else {
            setSaveResultMessage(res.Message)
            formikLegal.setSubmitting(false)
          }
          setLoadingSave(false)
        }
      } else {
        setLoadingSave(false)
        setSaveResultMessage({ message: response.Message, code: response.Code })
        formikLegal.setSubmitting(false)
      }
      setSuccessModal(true)
      setLoadingSave(false)
    }
  }

  const updateClient = async customer => {
    setLoadingSave(true)

    const response = await dispatch(updateCustomerAction(customer))
    if (response?.data?.Status?.Code === 200) {
      const listPriceIdsAdd = []
      const listPriceIdsDelete = []

      valueState.map(list => {
        const isIn = valueEditState.some(
          oldList => oldList.PriceListId === list.PriceListId
        )
        if (!isIn) {
          listPriceIdsAdd.push(list.PriceListId)
        }
      })

      valueEditState.map(list => {
        const isIn = valueState.some(
          oldList => oldList.PriceListId === list.PriceListId
        )
        if (!isIn) {
          listPriceIdsDelete.push(list.LinkId)
        }
      })
      if (listPriceIdsAdd?.length > 0) {
        const payload = getPriceListPayload(
          listPriceIdsAdd,
          customer?.CustomerInfo?.CustomerId || customer?.customerId,
          true
        )
        const res = await dispatch(saveListPricesAction(payload))
        if (!res.Message && listPriceIdsDelete?.length === 0) {
          setSuccessModalUpdate(true)
        } else if (res.Message && listPriceIdsDelete?.length === 0) {
          setSaveResultMessage(res.Message)
          setSuccessModal(true)
        }
      }
      if (listPriceIdsDelete.length > 0) {
        listPriceIdsDelete.map(async deleteItem => {
          const idDelete = deleteItem
          const res = await dispatch(deleteListPricesAction(idDelete))
          if (
            !res.Message ||
            (res?.Message === 'OK' &&
              idDelete === listPriceIdsDelete[listPriceIdsDelete.length - 1])
          ) {
            setSuccessModalUpdate(true)
          } else {
            setSaveResultMessage(res.Message)
            setSuccessModal(true)
          }
        })
      } else {
        needAutoclose && handleClose?.()
        setSuccessModalUpdate(true)
        setLoadingSave(false)
      }
    } else {
      formikNatural.setSubmitting(false)
      formikLegal.setSubmitting(false)
      setLoadingSave(false)
      console.error('Error:', response.toString())
      throw new Error(response.data?.Status?.Message)
    }
    setLoadingSave(false)
  }

  const { formikLegal, formikNatural } = useFormLogic({
    valueStateSelectCustomerGroup,
    validateAndSetAddress,
    updateClient,
    saveClient,
  })

  const fetchTypeTelephone = async () => {
    const Data = await dispatch(getTelephoneTypesAction())
    if (Data && Data?.length > 0) {
      setTipoTelefono(Data)
    } else {
      console.error('Error:', Data.toString())
      throw new Error(Data.toString())
    }
    !clientSelected?.edit && setLoader(false)
  }

  const fetchCountries = async () => {
    const Data = await dispatch(getCountriesAction())
    if (Data?.length <= 0) {
      console.error('Error:', Data.toString())
      throw new Error(Data.toString())
    }
  }

  const fetchDocumentType = async DocumentTypeId => {
    const Data = await dispatch(getDocumentTypesAction(DocumentTypeId))
    if (Data && Data?.length > 0) {
      setDocumentType(Data)
    } else {
      console.error('Error:', Data.toString())
      throw new Error(Data.toString())
    }
  }

  const fetchCustomerbyId = async customerId => {
    setLoader(true)

    const Data = await dispatch(getCustomerById(customerId))

    if (Data && Data?.CustomerType) {
      const customerTypeId = Data.CustomerType?.CustomerTypeId
      const payload = {
        CustomerId: customerId,
        PageNumber: 1,
        PageSize: 10,
      }

      if (customerTypeId.toLowerCase() === customerNaturalId.toLowerCase()) {
        setTabNatural(true)
        setTabJuridica(false)

        const infoData = Data
        let PHONE_ID_NATURAL = []
        let NUMBER_NATURAL = []
        let PHONE_TYPE_ID_NATURAL = []

        let PHONEIDNATURAL = ''
        let NUMBERNATURAL = ''
        let PHONETYPEIDNATURAL = ''

        if (
          infoData?.CustomerInfo?.PriceList !== null &&
          infoData?.CustomerInfo?.PriceList?.length > 0 &&
          isCallCenter
        ) {
          const listPricesCustomer = await dispatch(
            getListPricesByCustomerAction(payload)
          )
          if (listPricesCustomer?.length > 0) {
            listPricesCustomer.map(list => {
              valueState.push(list)
              valueEditState.push(list)
            })
            setValueState(valueState)
            setValueEditState(valueEditState)
          }
        }

        if (infoData?.TelephoneList !== null) {
          PHONE_ID_NATURAL = infoData?.TelephoneList?.find(
            item => item?.TelephoneInfo?.TelephoneId
          )
          NUMBER_NATURAL = infoData?.TelephoneList?.find(
            item => item?.TelephoneInfo?.Number
          )
          PHONE_TYPE_ID_NATURAL = infoData?.TelephoneList?.find(
            item => item?.TelephoneInfo?.TelephoneTypeId
          )

          PHONEIDNATURAL = PHONE_ID_NATURAL
            ? PHONE_ID_NATURAL?.TelephoneInfo?.TelephoneId
            : ''
          NUMBERNATURAL = NUMBER_NATURAL
            ? NUMBER_NATURAL?.TelephoneInfo?.Number
            : ''
          PHONETYPEIDNATURAL = PHONE_TYPE_ID_NATURAL
            ? PHONE_TYPE_ID_NATURAL?.TelephoneInfo?.TelephoneTypeId
            : ''
        } else {
          PHONEIDNATURAL = ''
          NUMBERNATURAL = ''
          PHONETYPEIDNATURAL = ''
        }

        let EMAIL_ID = []
        let ADDRESS_EMAIL = []
        let EMAIL_TYPE_ID = []
        let DEFAULT_EMAIL = []

        let EMAILID = ''
        let ADDRESSEMAIL = ''
        let EMAILTYPEID = ''
        let DEFAULTEMAIL = ''

        if (infoData?.EmailList !== null) {
          EMAIL_ID = infoData?.EmailList?.find(item => item?.EmailInfo?.EmailId)
          ADDRESS_EMAIL = infoData?.EmailList?.find(
            item => item?.EmailInfo?.Address
          )
          EMAIL_TYPE_ID = infoData?.EmailList?.find(
            item => item?.EmailInfo?.EmailTypeId
          )
          DEFAULT_EMAIL = infoData?.EmailList?.find(
            item => item?.EmailInfo?.DefaultEmail
          )

          EMAILID = EMAIL_ID ? EMAIL_ID?.EmailInfo?.EmailId : ''
          ADDRESSEMAIL = ADDRESS_EMAIL ? ADDRESS_EMAIL?.EmailInfo?.Address : ''
          EMAILTYPEID = EMAIL_TYPE_ID
            ? EMAIL_TYPE_ID?.EmailInfo?.EmailTypeId
            : ''
          DEFAULTEMAIL = DEFAULT_EMAIL
            ? DEFAULT_EMAIL?.EmailInfo?.DefaultEmail
            : ''
        } else {
          EMAILID = ''
          ADDRESSEMAIL = ''
          EMAILTYPEID = ''
          // eslint-disable-next-line no-unused-vars
          DEFAULTEMAIL = ''
        }

        setValueStateSelect({
          Description: Data.Gender?.Description || null,
          genderId: Data.Gender?.GenderId,
        })
        setValueStateSelectCustomer(Data.CustomerGroupList?.[0] || null)

        infoData?.CustomerGroupList?.find(item => item?.CustomerGroupId)
        const CUSTOMERGROUPID = Data?.CustomerGroupList?.find(
          item => item?.CustomerGroupId
        )?.CustomerGroupId
        const CUSTOMERGROUPDESCRIPTION = Data?.CustomerGroupList?.find(
          item => item?.CustomerGroupId
        )?.Description

        formikNatural.setValues({
          ...formikNatural.values,
          tenant: 2,
          aludraAPP: true,
          firstName:
            Data.CustomerInfo?.FirstName !== null
              ? Data.CustomerInfo?.FirstName
              : '',
          lastName:
            Data.CustomerInfo?.LastName !== null
              ? Data.CustomerInfo?.LastName
              : '',
          fullName:
            Data.CustomerInfo?.FullName !== null
              ? Data.CustomerInfo?.FullName
              : '',
          idNumber:
            Data.CustomerInfo?.IDNumber !== null
              ? Data.CustomerInfo?.IDNumber
              : '',
          birthdate:
            Data.CustomerInfo?.Birthdate !== null
              ? moment(Data.CustomerInfo?.Birthdate).format('YYYY-MM-DD')
              : '',
          cP_Passport: Data.CustomerInfo?.IDNumber
            ? Data.CustomerInfo?.IDNumber
            : '',
          gender: Data.Gender !== null ? Data.Gender?.Description : '',
          genderId: Data.Gender !== null ? Data.Gender?.GenderId : '',
          DV: Data.CustomerInfo?.DV !== null ? Data.CustomerInfo?.DV : '',
          nationalityId:
            Data.CustomerInfo?.NationalityId !== null
              ? Data.CustomerInfo?.NationalityId
              : '',
          documentTypeId:
            Data.CustomerInfo?.DocumentTypeId !== null
              ? Data.CustomerInfo?.DocumentTypeId
              : '',
          customerTypeId:
            Data.CustomerInfo?.CustomerTypeId !== null
              ? Data.CustomerInfo?.CustomerTypeId
              : '',
          customerId:
            Data.CustomerInfo?.CustomerId !== null
              ? Data.CustomerInfo?.CustomerId
              : '',
          generalTelephone: [
            {
              telephoneId: PHONEIDNATURAL,
              number: NUMBERNATURAL,
              telephoneTypeId: PHONETYPEIDNATURAL,
            },
          ],
          generalEmail: [
            {
              emailId: EMAILID,
              address: ADDRESSEMAIL,
              emailTypeId: EMAILTYPEID,
              defaultEmail: true,
            },
          ],
          generalGroup: CUSTOMERGROUPID
            ? [
                {
                  customerGroupId: CUSTOMERGROUPID,
                  Description: CUSTOMERGROUPDESCRIPTION,
                },
              ]
            : [],
          rolesName: ['CUSTOMER'],
        })
      } else {
        setTabNatural(false)
        setTypeClient(customerLegalId)
        setTabJuridica(true)

        const info = Data.AdministrativeStoreInfo
        const infoData = Data
        let PHONE_ID = []
        let NUMBER = []
        let TELEPHONE_TYPE_ID = []
        let DEFAULT_MOBILE = []

        let PHONEID = ''
        let PHONE = ''
        let DEFAULTMOBILE = ''
        let TELEPHONETYPEID = ''

        let EMAIL_ID = []
        let EMAIL_ADDRESS = []
        let DEFAULT_TYPE_ID = []

        let EMAILID = ''
        let regexEmail = ''
        let DEFAULTTYPEID = ''

        let Tenant = 0
        let StoreId = ''

        if (
          infoData?.CustomerInfo?.PriceList !== null &&
          infoData?.CustomerInfo?.PriceList?.length > 0 &&
          isCallCenter
        ) {
          const listPricesCustomer = await dispatch(
            getListPricesByCustomerAction(payload)
          )
          if (listPricesCustomer?.length > 0) {
            listPricesCustomer.map(list => {
              valueState.push(list)
              valueEditState.push(list)
            })
            setValueState(valueState)
            setValueEditState(valueEditState)
          }
        }

        if (info !== null) {
          PHONE_ID = info?.TelephoneList?.find(
            item => item?.TelephoneInfo?.TelephoneId
          )
          NUMBER = info?.TelephoneList?.find(
            item => item?.TelephoneInfo?.Number
          )
          TELEPHONE_TYPE_ID = info?.TelephoneList?.find(
            item => item?.TelephoneInfo?.TelephoneTypeId
          )
          DEFAULT_MOBILE = info?.TelephoneList?.find(
            item => item?.TelephoneInfo?.DefaultMobile
          )

          EMAIL_ID = info?.EmailList?.find(item => item?.EmailInfo?.EmailId)
          EMAIL_ADDRESS = info?.EmailList?.find(
            item => item?.EmailInfo?.Address
          )
          DEFAULT_TYPE_ID = info?.EmailList?.find(
            item => item?.EmailInfo?.EmailTypeId
          )

          PHONEID = PHONE_ID ? PHONE_ID.TelephoneInfo.TelephoneId : ''
          PHONE = NUMBER ? NUMBER.TelephoneInfo.Number : ''
          TELEPHONETYPEID = TELEPHONE_TYPE_ID
            ? TELEPHONE_TYPE_ID.TelephoneInfo.TelephoneTypeId
            : ''
          DEFAULTMOBILE = DEFAULT_MOBILE
            ? DEFAULT_MOBILE.TelephoneInfo.Number
            : ''

          EMAILID = EMAIL_ID ? EMAIL_ID.EmailInfo.EmailId : ''
          regexEmail = EMAIL_ADDRESS ? EMAIL_ADDRESS.EmailInfo.Address : ''
          DEFAULTTYPEID = DEFAULT_TYPE_ID
            ? DEFAULT_TYPE_ID.EmailInfo.EmailTypeId
            : ''

          Tenant = Data.CustomerInfo.SaaSId
          StoreId = info?.StoreInfo.StoreId
        } else {
          PHONEID = ''
          PHONE = ''
          TELEPHONETYPEID = ''
          // eslint-disable-next-line no-unused-vars
          DEFAULTMOBILE = ''

          EMAILID = ''
          regexEmail = ''
          DEFAULTTYPEID = ''

          StoreId = ''
        }

        let CUSTOMER_GROUP_ID = []

        let CUSTOMERGROUPID = ''
        let CUSTOMERGROUPDESCRIPTION = ''

        if (infoData?.CustomerGroupList !== null) {
          CUSTOMER_GROUP_ID = infoData?.CustomerGroupList?.find(
            item => item?.CustomerGroupId
          )
          CUSTOMERGROUPID = CUSTOMER_GROUP_ID
            ? CUSTOMER_GROUP_ID?.CustomerGroupId
            : ''
          CUSTOMERGROUPDESCRIPTION = CUSTOMER_GROUP_ID
            ? CUSTOMER_GROUP_ID?.Description
            : ''
        } else {
          CUSTOMERGROUPID = ''
          CUSTOMERGROUPDESCRIPTION = ''
        }
        setValueStateSelectCustomerGroup({
          Description: CUSTOMERGROUPDESCRIPTION || null,
          customerGroupId: CUSTOMERGROUPID || '',
        })

        formikLegal.setValues({
          ...formikLegal.values,
          tenant: Tenant,
          aludraAPP: true,
          customerId:
            Data.CustomerInfo?.CustomerId !== null
              ? Data.CustomerInfo?.CustomerId
              : '',
          country: 'panama',
          email:
            Data.CustomerInfo.Email !== null ? Data.CustomerInfo?.Email : '',
          customerTypeId:
            Data.CustomerType !== null ? Data.CustomerType?.CustomerTypeId : '',
          customerStatus: 'active',
          customerType:
            Data.CustomerType !== null ? Data.CustomerType?.Description : '',
          ruc: Data.CustomerInfo?.RUC !== null ? Data.CustomerInfo?.RUC : '',
          dv: Data.CustomerInfo?.DV !== null ? Data.CustomerInfo?.DV : '',
          documentTypeId:
            Data.CustomerInfo?.DocumentTypeId !== null
              ? Data.CustomerInfo?.DocumentTypeId
              : '',
          legalName:
            Data.CustomerInfo?.LegalName !== null
              ? Data.CustomerInfo?.LegalName
              : '',
          comercialName:
            Data.CustomerInfo?.ComercialName !== null
              ? Data.CustomerInfo?.ComercialName
              : '',
          generalStore: [
            {
              StoreId,
              AdministrativeLocation: true,
              generalTelephone: [
                {
                  telephoneId: PHONEID,
                  number: PHONE,
                  telephoneTypeId: TELEPHONETYPEID,
                  defaultMobile: true,
                },
              ],
              generalEmail: [
                {
                  emailId: EMAILID,
                  address: regexEmail,
                  emailTypeId: DEFAULTTYPEID,
                },
              ],
            },
          ],
          generalGroup: CUSTOMERGROUPID
            ? [{ customerGroupId: CUSTOMERGROUPID }]
            : [],
          rolesName: ['CUSTOMER'],
        })
      }
    } else {
      console.error('Error:', Data.toString())
      throw new Error(Data.toString())
    }
    setLoader(false)
  }

  const commonProps = {
    validateAndSetAddress,

    tipoTelefono,
    documentType,

    setValueState,
    valueState,

    updateClient,
    saveClient,

    loadingSave,

    setHasAddress,
    hasAddress,
    setCountryCode,
    setProvinceCode,
    setDistrictCode,
    setTownshipCode,
    street,
    setStreet,
    name,
    setName,
  }
  const tabNaturalProps = {
    formikNatural,
    setValueStateSelect,
    valueStateSelect,
    setValueStateSelectCustomer,
    valueStateSelectCustomer,
    ...commonProps,
  }
  const legalProps = {
    formikLegal,
    setValueStateSelectCustomerGroup,
    valueStateSelectCustomerGroup,
    ...commonProps,
  }

  useEffect(() => {
    fetchTypeTelephone()
    if (clientSelected.edit) {
      fetchCustomerbyId(clientSelected.customerID)
    }
  }, [])

  useLayoutEffect(() => {
    fetchDocumentType(typeClient)
  }, [typeClient])

  useEffect(() => {
    fetchCountries()
  }, [mapKey])

  if (loader) return <MiniLoading />

  return (
    <>
      <section className="crear-client-content">
        <div className="crear-client-main">
          <Tabs
            transition={false}
            id="noanim-tab-example"
            className="myClass mb-3"
            onSelect={index => {
              setTypeClient(index)
              setMapKey(index)
              clearAddress()
            }}>
            {tabNatural && (
              <Tab
                eventKey={customerTypeId.natural}
                title={t('CREATE_CLIENT_T.NATURAL_TITLE')}>
                <TabNatural props={tabNaturalProps} />
              </Tab>
            )}
            {tabJuridica && createComercialClient && (
              <Tab
                eventKey={customerTypeId.legal}
                title={t('CREATE_CLIENT_T.JURIDIC_TAB')}>
                <TabLegal props={legalProps} />
              </Tab>
            )}
          </Tabs>
        </div>
      </section>

      <AlertModal
        show={successModal}
        label={saveResultMessage?.message || saveResultMessage}
        onClick={() => {
          setSuccessModal(false)
          saveResultMessage?.code !== 406 && handleClose?.()
        }}
      />

      <AlertModal
        show={successModalUpdate}
        label={t('CREATE_CLIENT_T.CHANGES_SAVED')}
        onClick={() => {
          setSuccessModalUpdate(false)
          handleClose && handleClose()
        }}
      />

      <EmailModal />
    </>
  )
}

export default CrearCliente
